
import { ColorString } from "../../map/Colors";
import { buoyIconSvg } from "../../map/Icons";
import { TimeDelta } from "../../misc/TimeDelta";
import { FeatureType } from "../../misc/Types";
import { LayerGroup, SingleLayerGroup } from "../LayerGroup";
import { AddIconParams, BUILTIN_ICON, ClientFilter, Filter, IconResult, LayerParamOptions, LayerType } from "../LayerTypes";
import { addSvgIcon, fillInDefaultOptions, makeSimpleFilter } from "./common";



const DEFAULT_BUOY_ICON_EXPR = [
  "concat",
  [
    "case",
    [
      "all",
      ["has", "client"],
      ["==", ["get", "client"], "Vineyard-DEME"],
    ],
    "vineyardDemeBase",
    [
      "all",
      ["has", "recentDetections"],
      ["to-boolean", ["get", "recentDetections"]],
    ],
    "detection",
    "base",
  ],
  "BuoyIcon",
];

const HAS_FILL_COLOR_EXPR = [
  "all",
  ["has", "client"],
  ["has", "fillColor"],
  ["==", ["typeof", ["get", "fillColor"]], "string"]
];


const MAKE_FILL_COLOR_ICON_NAME = [
  "concat",
  ["get", "client"],
  "-buoys-",
  ["get", "fillColor"]
];


const addBuoyIcon = function(params: AddIconParams): IconResult | Promise<IconResult> {
  // if there's no fill color, the icon is built in
  if (!params.iconName.fillColor) {
    return IconResult.Added;
  }

  return addSvgIcon(
    params, 
    (colors) => buoyIconSvg(colors.fillColor), 
    params.iconName.fillColor as ColorString,
    50,
  );
};


export const BUOY_LAYER_GROUP: LayerGroup = new SingleLayerGroup({
  layerId: FeatureType.Buoys,
  sourceId: FeatureType.Buoys,
  hasAgeDecay: false,
  addIcon: addBuoyIcon,
  // the builtin icons already exist in the mapbox style, all other icons 
  // should be loaded lazily as needed. 
  shouldLazilyLoadIcons: true,
  factory: function(opts?: LayerParamOptions): LayerType.Symbol {
    const { visible } = fillInDefaultOptions(opts);

    return {
      id: FeatureType.Buoys,
      type: "symbol",
      source: FeatureType.Buoys,
      filter: ["==", ["get", "dataType"], "buoy"],
      layout: {
        "visibility": visible ? "visible" : "none",
        "icon-image": [
          "case",
          HAS_FILL_COLOR_EXPR, MAKE_FILL_COLOR_ICON_NAME,
          DEFAULT_BUOY_ICON_EXPR,
        ],
        "icon-size": 0.4,
        "icon-allow-overlap": true
      }
    };
  }
});


function fishingGearFilterFactory(timeDelta: TimeDelta, clientFilter?: null | ClientFilter): LayerType.Circle["filter"] {
  const base = makeSimpleFilter({ dataType: "fishingGear", timeDelta });

  switch (clientFilter?.type) {
    case Filter.Exclude:
      base.push(["!=", ["get", "client"], clientFilter.client]);
      break;
    case Filter.Include:
      base.push(["==", ["get", "client"], clientFilter.client]);
      break;
  }

  return base;
}

export const FISHING_GEAR_GROUP: LayerGroup = new SingleLayerGroup({
  layerId: FeatureType.FishingGear,
  sourceId: FeatureType.FishingGear,
  hasAgeDecay: true,
  addIcon: BUILTIN_ICON,
  factory: function(opts?: LayerParamOptions): LayerType.Symbol {
    const { visible, timeDelta, clientFilter } = fillInDefaultOptions(opts);

    return {
      id: FeatureType.FishingGear,
      type: "symbol",
      source: FeatureType.FishingGear,
      filter: fishingGearFilterFactory(timeDelta, clientFilter),
      layout: {
        "visibility": visible ? "visible" : "none",
        "icon-image": "fishingGear",
        "icon-size": 0.45,
        "icon-allow-overlap": true
      }
    };
  }
});
