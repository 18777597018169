/** Common helpers for layer implementations */
import { HSL, ColorString, RGB } from "../../map/Colors";
import { formatColor, loadSvgImage, Size } from "../../map/Icons";
import { FixedArray } from "../../misc/TypeUtils";
import { AddIconFn, AddIconParams, IconName, IconResult, LayerParamOptions, LayerType } from "../LayerTypes";
import { Icon, svgBuilderFactory, rendererFactory } from "../icon";
import type { GeoType } from "../../misc/Geo";
import mapboxgl from "mapbox-gl";
import { Seconds, TimeDelta } from "../../misc/TimeDelta";




const ICON_PULSE_DURATION_MS = 5000;



export const DEFAULT_LAYER_OPTIONS: Required<LayerParamOptions> = {
  visible: true,
  clientFilter: null,
  timeDelta: { 
    new: null,
    old: { delta: 24 * 60 * 60 as Seconds, label: "24 hours" },
  },
};


export function fillInDefaultOptions(opts?: LayerParamOptions): Required<LayerParamOptions> {
  const out = opts ?? DEFAULT_LAYER_OPTIONS;

  out.timeDelta = out.timeDelta ?? DEFAULT_LAYER_OPTIONS.timeDelta;
  out.clientFilter = out.clientFilter ?? DEFAULT_LAYER_OPTIONS.clientFilter;
    
  return out as Required<LayerParamOptions>;
}

export interface SimpleFilterOpts {
    dataType: string;
    timeDelta: TimeDelta;
    geometryType?: GeoType;
}

export function mapboxExprClamp(innerExpr: mapboxgl.Expression, min: number, max: number): mapboxgl.Expression {
  return ["min", ["max", innerExpr, min], max];
}


export function makeSimpleFilter(opts: SimpleFilterOpts): Exclude<LayerType.Any["filter"], null | undefined> {
  const { dataType, timeDelta, geometryType } = opts;
    
    
  const filter: LayerType.Any["filter"] = [
    "all",
    ["==", ["get", "dataType"], dataType],
  ];

  if (geometryType) {
    filter.push(["==", ["geometry-type"], geometryType]);
  }

  filter.push(["has", "age"]);
  filter.push(["<", ["get", "age"], Math.abs(timeDelta.old.delta)]);

  if (timeDelta.new) {
    filter.push([">", ["get", "age"], Math.abs(timeDelta.new.delta)]);
  }

  return filter;
}


export interface IconColors {
    borderColor: ColorString | HSL | RGB;
    fillColor: ColorString | HSL | RGB;
}


/** IconColors, but with each color known to be formatted as a hex color code. */
export interface IconColorStrings {
    borderColor: ColorString;
    fillColor: ColorString;
}

export function toIconColorStrings(colors: IconColors): IconColorStrings {
  return {
    borderColor: formatColor(colors.borderColor),
    fillColor: formatColor(colors.fillColor),
  };
}


export type SvgBuilder = (colors: IconColorStrings) => string;

// helper fn for adding a single svg icon, which only requires a raw svg string builder + icon name; 
export function addSvgIcon(
  params: AddIconParams,
  svgBuilder: SvgBuilder,
  fillColor: ColorString | RGB | HSL,
  size?: number | Size
): IconResult | Promise<IconResult> {
  const iconName = params.iconName.formatIconName();
  if (params.map.hasImage(iconName)) {
    return IconResult.NotAdded;
  }   

  const svgString = svgBuilder({ 
    fillColor: formatColor(fillColor), 
    borderColor: formatColor(params.borderColor),
  });

  return loadSvgImage(svgString, size).then((img) => {
    if (!params.map.hasImage(iconName)) {
      params.map.addImage(iconName, img); 
      return IconResult.Added;
    }

    return IconResult.NotAdded;
  });
}


export function addIconFnBuilder(
  icon: Icon, 
  getFillColor: (iconName: IconName) => ColorString | RGB | HSL, 
  pulsing: boolean,
  size?: number,
): AddIconFn {
  if (pulsing) {
    return addPulsingIconBuilder(icon, getFillColor, size);
  } else {
    const svgBuilder = svgBuilderFactory(icon);

    return function(params: AddIconParams): IconResult | Promise<IconResult> {
      const fillColor = getFillColor(params.iconName);
      return addSvgIcon(params, svgBuilder, fillColor);
    };
  }

}


export interface StyleImageInterface {
    height: number;
    width: number;
    map: mapboxgl.Map;
    context: null | CanvasRenderingContext2D;
    data: Uint8ClampedArray;
    colors: IconColorStrings;
    onAdd: () => void;
    render: () => boolean;
}


export interface RenderFrameProps {
    ctx: CanvasRenderingContext2D;
    size: number;
    t: number;
    colors: IconColorStrings;
}

export interface PulsingIconProps { 
    map: mapboxgl.Map;
    colors: IconColors;
    renderFrame(props: RenderFrameProps): boolean;
    /** Defaults to 64 if not specified */
    size?: number;
}


export function buildPulsingImageInterface(props: PulsingIconProps): StyleImageInterface {
  const size = props.size ?? 64;
  const colors = toIconColorStrings(props.colors);
  const { map, renderFrame } = props;

  const NORMALIZE_PHASE = 2 * Math.PI / ICON_PULSE_DURATION_MS;

  return {
    map,
    colors,
    width: size,
    height: size,
    context: null,
    data: new Uint8ClampedArray(size * size * 4),
    onAdd: function () {
      const canvas = document.createElement("canvas");
      canvas.height = size;
      canvas.width = size;
      this.context = canvas.getContext("2d", { willReadFrequently: true });
    },
    render: function () {
      if (!this.context) {
        return false;
      }

      const phase = (performance.now() % ICON_PULSE_DURATION_MS) * NORMALIZE_PHASE;
      const t = (Math.sin(phase) + 1) / 2;


      const changed = renderFrame({ ctx: this.context, size, t, colors });

      if (changed) {
        this.data = this.context.getImageData(0, 0, size, size).data;
        map.triggerRepaint();
      }

      return changed;
    },
  };
}


export function addPulsingIconToMap(
  props: { iconName: IconName } & PulsingIconProps
): IconResult {
  const { iconName, ...pulsingIconProps } = props;
    
  const fmtIconName = iconName.formatIconName();

  if (pulsingIconProps.map.hasImage(fmtIconName)) {
    return IconResult.NotAdded;
  }

  const icon = buildPulsingImageInterface(pulsingIconProps);

  pulsingIconProps.map.addImage(fmtIconName, icon);
    
  return IconResult.Added;
}




export function addPulsingIconBuilder(
  icon: Icon,
  getFillColor: (iconName: IconName) => ColorString | HSL | RGB,
  size?: number,
): AddIconFn {
  const renderFrame = rendererFactory(icon);

  return function(params: AddIconParams): IconResult | Promise<IconResult> {
    const { map, iconName, borderColor } = params;
        
    return addPulsingIconToMap({
      iconName,
      map,
      renderFrame,
      colors: {
        borderColor,
        fillColor: getFillColor(iconName),
      },
      size,
    });
  };
}


export function getAgeDecayFillColorBuilder(
  ageDecayColors: FixedArray<HSL, 10>,
  defaultFillColor: ColorString | HSL | RGB,
): (iconName: IconName) => ColorString | HSL | RGB {
  return function (iconName: IconName): ColorString | HSL | RGB {
    if (typeof iconName.ageDecayIndex === "number") {
      const color = ageDecayColors[iconName.ageDecayIndex];
      if (color) {
        return color;
      }
    }

    return defaultFillColor;
  };
} 

