import React from "react";
import ReactDOM from "react-dom";

import { ClientsAPI } from "../firebase/API";
import { extractMapSourcesByClient, MapSourcesByClient } from "../firebase/Firebase";

import { 
  loadSvgImage, 
  newSightingWithImage, 
  SIGHTING_DECAY_COLORS, 
  regularSightingSvg,
  prioritySightingSvg, 
  stationIconSvg,
  ellipseIconSvg,
  bearingLineIconSvg,
  polygonIconSvg,
  birdSightingIconSvg,
  priorityBirdSightingIconSvg,
} from "./Icons";

import {
  DataType,
  ClientDoc,
  LegendItem,
  MapMenuConfig,
  MapDoc,
  Mapbox,
  PopupRef,
  FeatureType
} from "../misc/Types";

import { ColorString, generateNRandomColors, HSL, RGB } from "./Colors";

import Firestore from "../firebase/Firestore";
import { size, debounce } from "lodash";
import mapboxgl from "mapbox-gl";
import MultiMapPopup from "../components/map/MultiMapPopup";
import { sortByDistanceTo } from "../misc/Geo";
import { Layer, MiscLayer } from "../layers/LayerTypes";

const BASE_STATION_ICON = new URL("/public/static/icons/base-station.png", import.meta.url);
const PRIORITY_SIGHTING_ICON = new URL("/public/static/icons/prioritySighting.svg", import.meta.url);
const BASE_BUOY_ICON = new URL("/public/static/icons/baseBuoyIcon.svg", import.meta.url);
const DETEC_BUOY_ICON = new URL("/public/static/icons/detectionBuoyIcon.svg", import.meta.url);
const BASE_GLIDER_ICON = new URL("/public/static/icons/baseGliderIcon.svg", import.meta.url);
const DETEC_GLIDER_ICON = new URL("/public/static/icons/detectionGliderIcon.svg", import.meta.url);


export function rasterizeAndLoadSvgImage(
  map: mapboxgl.Map, 
  iconName: string, 
  rawSvg: string,
  size: number = 64,
): Promise<void> {
  if (map.hasImage(iconName)) {
    return Promise.resolve();
  }

  return loadSvgImage(rawSvg, size).then(image => {
    if (!map.hasImage(iconName)) {
      map.addImage(iconName, image);
    }
  });
} 

function buildDefaultAcoustic(): Record<string, LegendItem> {
  return { 
    ellipses: {
      icons: [{
        type: "svg",
        svg: ellipseIconSvg("rgba(255, 255, 255, 0.6)" as ColorString, "#000000" as ColorString),
      }],
      text: "Localized Acoustic Detections",
      layers: [FeatureType.Ellipses, MiscLayer.EllipseOutlines],
    },
    bearingLines: {
      icons: [{ type: "svg", svg: bearingLineIconSvg("#000000" as ColorString) }],
      text: "Acoustic Detections",
      layers: [FeatureType.BearingLines, MiscLayer.BearingLineOutlines],
    }
  };
}

function buildDefaultMapImages(): MapMenuConfig {
  return {
    polygons: {
      slowZones: {
        icons: [{ 
          type: "svg", 
          svg: polygonIconSvg("#000000" as ColorString, "rgba(247, 253, 4, 0.15)" as ColorString)
        }],
        text: "NOAA Slow Zones",
        layers: [FeatureType.SlowZones],
      },
      SMAs: {
        icons: [{
          type: "svg",
          svg: polygonIconSvg("#000000" as ColorString, "rgba(255, 0, 0, 0.2)" as ColorString)
        }],
        layers: [FeatureType.Sma],
        text: "NOAA Seasonal Management Areas",
      }
    },
    stations: {
      base: {
        icons: [{ type: "svg", svg: stationIconSvg("#000000" as ColorString) }],
        text: "Vehicle",
        layers: [FeatureType.Stations, MiscLayer.StationTracklines],
      },
      dropdown: {},
    },
    sightings: {
      base: {
        icons: [{ type: "svg", svg: regularSightingSvg("#000000" as ColorString) }],
        text: "Shared Animal Sightings",
        layers: [FeatureType.Sightings],
      },
      basePriority: {
        icons: [{ type: "svg", svg: prioritySightingSvg("#000000" as ColorString) }],
        text: "Shared Priority Sightings",
        layers: [FeatureType.Sightings],
      },
      dropdown: {},
    },
    birdSightings: {
      base: {
        icons: [{ type: "svg", svg: birdSightingIconSvg("#000000" as ColorString) }],
        text: "Avian Sightings",
        layers: [MiscLayer.BirdSightings],
      },
      basePriority: {
        icons: [{ type: "svg", svg: priorityBirdSightingIconSvg("#000000" as ColorString) }],
        text: "Priority Avian Sightings",
        layers: [MiscLayer.PriorityBirdSightings],
      },
      dropdown: {},
    },
    // TODO: rework this map to support a specific ordering.
    // this needs to be set here as undefined, that way if it gets set in the
    // MapSetup constructor it sits below sightings in the menu.
    acoustic: undefined,
    buoys: {
      baseBuoy: {
        icons: [{ type: "img", src: BASE_BUOY_ICON.href }],
        text: "Buoys",
        layers: [FeatureType.Buoys],
      },
      detectionBuoy: {
        icons: [{ type: "img", src: DETEC_BUOY_ICON.href }],
        text: "Buoys with recent detections",
        layers: [FeatureType.Buoys],
      },
    },
    gliders: {
      baseGlider: {
        icons: [{ type: "img", src: BASE_GLIDER_ICON.href }],
        text: "Gliders",
        layers: [FeatureType.Gliders, MiscLayer.GliderTracklines],
      },
      detectionGlider: {
        icons: [{ type: "img", src: DETEC_GLIDER_ICON.href }],
        text: "Gliders with recent detections",
        layers: [FeatureType.Gliders, MiscLayer.GliderTracklines],
      },
    },
  };
}
  
/// inner records map clientId -> rawSvg string  
interface CachedSvgs {
  [FeatureType.Stations]: Record<string, string>;
  [FeatureType.Sightings]: Record<string, string>;
  [MiscLayer.BirdSightings]: Record<string, string>;
  [MiscLayer.PrioritySightings]: Record<string, string>;
  [MiscLayer.PriorityBirdSightings]: Record<string, string>;
}


class MapSetup {
  private _map?: Mapbox.Map;
  private _mapDocument: MapDoc;
  private _mapSources: MapSourcesByClient;
  private _mapImages = buildDefaultMapImages();
  private _mapMenuIconLoadPromise: null | Promise<void> = null;
  private _clientMappingPromise: null | Promise<void> = null;
  private _admin: boolean = false;
  private _colorGenerator: Generator<HSL, void, never> | null = null;
  private _clientColorMapping: Record<string, RGB | ColorString> = {};
  private _clientMapping: Record<string, string> = {};

  private _onMapImageChange: null | ((current: MapMenuConfig) => void) = null;

  private _rawIconSvgs: CachedSvgs = {
    [FeatureType.Stations]: {},
    [FeatureType.Sightings]: {},
    [MiscLayer.BirdSightings]: {},
    [MiscLayer.PrioritySightings]: {},
    [MiscLayer.PriorityBirdSightings]: {},
  };

  constructor(mapDocument: MapDoc) {
    this._mapDocument = mapDocument;
    this._mapSources = extractMapSourcesByClient(this._mapDocument);

    this._admin = mapDocument.id === "admin";
    
    if (this._mapDocument.leaseAreas) {
      this._mapImages.polygons.leaseAreas = {
        icons: [
          { type: "svg", svg: polygonIconSvg("#000000" as ColorString, "rgba(255, 255, 255, 0.6)" as ColorString) },
          // { type: "svg", svg: polygonIconSvg("#000000", "rgba(53, 92, 192, 0.20)") },
        ],
        layers: [FeatureType.LeaseAreas],
        text: "Lease Areas"
      };
    }

    if (this._mapDocument.showAcousticData) {
      this._mapImages.acoustic = buildDefaultAcoustic();
    }
 
    this._clientMappingPromise = Firestore.getClientMapping().then(this.mergeClientMapping);
  }

  public setMapImageChangeHandler = (handler: (curr: MapMenuConfig) => void): void => {
    this._onMapImageChange = debounce(handler, 200, { trailing: true });
    this._onMapImageChange(this._mapImages);
  };

  private mergeClientMapping = (mapping: Record<string, string>): void => {
    this._clientMapping = { ...this._clientMapping, ...mapping };

    this._colorGenerator = generateNRandomColors(size(this._clientMapping));

    for (const client in this._clientMapping) {
      const res = this._colorGenerator.next();
      if (res.done) {
        break;
      }

      this._clientColorMapping[client] = HSL.format(res.value);
    }
  };

  public setMap = (map: Mapbox.Map): void => {
    if (this._map) { 
      return;
    }

    this._map = map;

    this._mapMenuIconLoadPromise = this.loadAllIcons(map);
  };


  private getNextGeneratedColor = (): Promise<HSL> => {
    const initPromise: Promise<void> = this._clientMappingPromise instanceof Promise 
      ? this._clientMappingPromise.then(() => {
        this._clientMappingPromise = null;
      })
      : Promise.resolve();

    return initPromise.then(() => {
      /* eslint-disable-next-line no-constant-condition */
      while (true) {
        if (this._colorGenerator === null) {
          const count = size(this._clientMapping);
          if (count === 0) {
            throw new Error("can't generate any colors if no clients are defined");
          }

          this._colorGenerator = generateNRandomColors(count);
        }

        const next = this._colorGenerator.next();
        if (next.done) {
          this._colorGenerator = null;
          continue;
        }

        return next.value;
      }
    });
  };

  public tryGetClientColor = (clientId: string): ColorString | null => {
    if (this._mapDocument.id === "admin") {
      const colorOverride = this._clientColorMapping[clientId];
      if (!colorOverride) {
        this.getNextGeneratedColor().then((hsl) => {
          this._clientColorMapping[clientId] = HSL.format(hsl);
        });
        return null;
      }
  
      return typeof colorOverride === "string"
        ? colorOverride
        : RGB.format(colorOverride);
    } else if (this._mapDocument.id === clientId) {
      return "#e88a17" as ColorString;
    } else {
      return "#000000" as ColorString;
    }
  };

  public getClientColor = async (clientId: string): Promise<ColorString> => {
    if (this._mapDocument.id === "admin") {
      let colorOverride = this._clientColorMapping[clientId];
      if (!colorOverride) {
        const hsl = await this.getNextGeneratedColor();
        colorOverride = HSL.format(hsl);
        this._clientColorMapping[clientId] = colorOverride;
      }
  
      return typeof colorOverride === "string"
        ? colorOverride
        : RGB.format(colorOverride);
    } else if (this._mapDocument.id === clientId) {
      return "#e88a17" as ColorString;
    } else {
      return "#000000" as ColorString;
    }
  };


  private getOrInsertRawSvgIcon = async (
    clientId: string,
    layer: keyof CachedSvgs,
    builder: (borderColor: ColorString | RGB) => string,
  ): Promise<string> => {
    // use the icon that already exists
    if (typeof this._rawIconSvgs[layer][clientId] !== "string") {
      const borderColor = await this.getClientColor(clientId);
      this._rawIconSvgs[layer][clientId] = builder(borderColor);
    }

    return this._rawIconSvgs[layer][clientId];
  };

  public forceLoadPrioritySightingIcon = async (clientId: string): Promise<void> => {
    if (!this._map) {
      return Promise.resolve();
    }

    const rawSvg = await this.getOrInsertRawSvgIcon(clientId, MiscLayer.PrioritySightings, prioritySightingSvg);
    
    return rasterizeAndLoadSvgImage(this._map, `${clientId}-prioritySighting`, rawSvg);
  };

  private loadIconInner = (
    map: mapboxgl.Map,
    clientId: string, 
    section: FeatureType.Stations | FeatureType.Sightings | MiscLayer.BirdSightings,
    layers: Layer[],
    textBuilder: (clientName: string) => string,
    baseBuilder: (borderColor: ColorString | RGB) => string,
    priority?: { layer: keyof CachedSvgs, builder: (borderColor: ColorString | RGB) => string },
    loadBaseImage?: boolean,
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {

        const svgLoadPromises = [
          this.getOrInsertRawSvgIcon(clientId, section, baseBuilder),
          priority ? this.getOrInsertRawSvgIcon(clientId, priority.layer, priority.builder) : Promise.resolve(null),
        ] as const;

        return Promise.all(svgLoadPromises).then(([rawSvg, prioritySvg]) => {
          const icons: LegendItem["icons"] = [
            { type: "svg", svg: rawSvg },
          ];
  
          const iconsToLoad: { iconName: string, svg: string }[] = [];
          if (priority && prioritySvg) {
            icons.push({ type: "svg", svg: prioritySvg });
            iconsToLoad.push({
              svg: prioritySvg,
              iconName: `${clientId}-${priority.layer}`
            });
          } 
          
          if (!priority || loadBaseImage) {
            iconsToLoad.push({
              svg: rawSvg,
              iconName: `${clientId}-${section}`
            });
          }
  
          this._mapImages[section].dropdown[clientId] = {
            icons,
            layers,
            clientId,
            text: textBuilder(this._clientMapping[clientId] ?? clientId),
          };
  
          if (typeof this._onMapImageChange === "function") {
            this._onMapImageChange(this._mapImages);
          }
  
          const promises: Promise<void>[] = [];
  
          for (const iconToLoad of iconsToLoad) {
            if (iconToLoad.iconName.endsWith("s")) {
              iconToLoad.iconName = iconToLoad.iconName.slice(0, iconToLoad.iconName.length - 1);
            }
            promises.push(rasterizeAndLoadSvgImage(map, iconToLoad.iconName, iconToLoad.svg));
          }
        
          Promise.all(promises)
            .catch(reject)
            .finally(resolve);
        });
      } catch (err) {
        reject(err);
      }
    });
  };

  
  public loadClientStationIcon = (map: mapboxgl.Map, clientId: string): Promise<void> => {
    return this.loadIconInner(
      map,
      clientId, 
      FeatureType.Stations,
      [FeatureType.Stations, MiscLayer.StationTracklines],
      (clientName: string) => `${clientName} Vehicles`,
      stationIconSvg,
    );
  };

  public loadClientSightingIcons = (map: mapboxgl.Map, clientId: string): Promise<void> => {
    return this.loadIconInner(
      map,
      clientId,
      FeatureType.Sightings,
      [FeatureType.Sightings, MiscLayer.PrioritySightings],
      (clientName: string) => `${clientName} Sightings`,
      regularSightingSvg,
      { layer: MiscLayer.PrioritySightings, builder: prioritySightingSvg },
    );
  };

  public loadClientBirdSightingIcons = (map: mapboxgl.Map, clientId: string): Promise<void> => {
    return this.loadIconInner(
      map,
      clientId,
      MiscLayer.BirdSightings,
      [MiscLayer.BirdSightings, MiscLayer.PriorityBirdSightings],
      (clientName: string) => `${clientName} Avian Sightings`,
      birdSightingIconSvg,
      { layer: MiscLayer.PriorityBirdSightings, builder: priorityBirdSightingIconSvg },
      true,
    );
  };

  private loadAllSourceTypeIcons = (
    map: mapboxgl.Map,
    sourceType: keyof MapSourcesByClient,
    perClientLoader: (map: mapboxgl.Map, clientId: string) => Promise<void>, 
  ): Promise<void> => {
    let sources = this._mapSources[sourceType] ?? [];

    if (sources === true) {
      sources = Object.keys(this._clientMapping);
    }

    // NOAA NARW sightings are shown to everyone, 
    // but isn't explicitly included in perms.
    if (sourceType === FeatureType.Sightings) {
      sources.push("NOAA");
    }

    if (sources.length === 0) {
      return Promise.resolve();
    }

    return Promise.all(sources.map(src => perClientLoader(map, src))) as unknown as Promise<void>;
  };


  private loadAllIcons = (map: mapboxgl.Map): Promise<void> => {
    // Make sure we wait for this to finish
    return (this._clientMappingPromise ?? Promise.resolve()).then(() => {
      return Promise.all([
        this.loadAllSourceTypeIcons(map, FeatureType.Stations, this.loadClientStationIcon),
        this.loadAllSourceTypeIcons(map, FeatureType.Sightings, this.loadClientSightingIcons),
        this.loadAllSourceTypeIcons(map, FeatureType.Sightings, this.loadClientBirdSightingIcons),
      ]);
    }) as unknown as Promise<void>;
  };

  public tryShowInfoPopup = (layers: string[], popupRef: PopupRef, args: Mapbox.PopupEventArgs): void => {
    if (!this._map) return;

    // if the popup window is open, close it, regardless of where/what we just
    // clicked on. This overrides the closeOnClick option in the
    // mapboxgl.Popup constructor in Map.js
    if (popupRef.current && popupRef.current.isOpen()) {
      popupRef.current.remove();
    }

    // get features near the click
    const features = this._map.queryRenderedFeatures(args.point, { layers: layers });

    // Only add popup if there's features to add
    if (popupRef.current && features.length > 0) {
      const sortedByDist = sortByDistanceTo(args.point, features);

      let filteredFeats: Mapbox.GeoJsonFeature[];
      // if the closest feature to the click was a sighting, only include sightings. 
      if (sortedByDist[0]?.properties?.dataType === "sighting") {
        const dedupeById: Record<string, Mapbox.GeoJsonFeature> = {};

        for (const feat of sortedByDist) {
          if (feat.properties?.dataType === "sighting") {
            dedupeById[feat.properties.id] = feat;
          }
        }

        filteredFeats = Object.values(dedupeById);
      } else {
        // otherwise, just use the top feature.
        filteredFeats = [features[0]];
      }

      const placeholder = document.createElement("div");  

      ReactDOM.render(
        <MultiMapPopup
          features={filteredFeats}
          sources={this._mapDocument.sources}
          closeHandler={popupRef.current.remove}             
          admin={this._admin}
        />,
        placeholder
      );

      popupRef.current
        .setLngLat(args.lngLat)
        .setDOMContent(placeholder)
        .addTo(this._map);
    }
  };

  public getClientInfos = (): Promise<Record<string, ClientDoc>> => {
    return ClientsAPI.getByIds(Object.keys(this._mapDocument.sources));
  };

  public loadSightingWithImageIcons = async (client: string, decayIndex?: number) => {
    if (!this._map) {
      return [];
    }
    
    const borderColor = await this.getClientColor(client);

    // inner function to add a single icon.
    const addSingleIcon = (index: number, fillColor: HSL) => {
      const iconName = `${client}-sighting-0${index}`;
      
      // once set, '_map' is non-null for the lifetime of this object.
      if (this._map!.hasImage(iconName)) {
        return;
      }

      const image = newSightingWithImage(this._map!, borderColor, fillColor);
      if (this._map && !this._map.hasImage(iconName)) {
        this._map.addImage(iconName, image);
      }
    };


    // if a single image was specified, add just that single one, otherwise 
    // add the entire set.
    if (
      typeof decayIndex === "number" 
      && !isNaN(decayIndex) 
      && decayIndex < SIGHTING_DECAY_COLORS.length
    ) {
      addSingleIcon(decayIndex, SIGHTING_DECAY_COLORS[decayIndex]);
    } else {
      let index = 0;
      while (index < SIGHTING_DECAY_COLORS.length) {
        addSingleIcon(index, SIGHTING_DECAY_COLORS[index]);      
        index += 1;
      }
    }   
  };

  public loadImages = (): Promise<MapMenuConfig> => {
    if (this._mapMenuIconLoadPromise === null && this._map) {
      this._mapMenuIconLoadPromise = this.loadAllIcons(this._map);
    }

    return (this._mapMenuIconLoadPromise ?? Promise.resolve()).then(() => this._mapImages);
  };
}

export default MapSetup;
