import mapboxgl, { CircleLayer, FillLayer, LineLayer, SymbolLayer } from "mapbox-gl";

import { FeatureType, isFeatureType } from "../misc/Types";
import { ColorString, HSL, RGB } from "../map/Colors";

import { z } from "zod"; 
import { Icon } from "./icon";
import { TimeDelta } from "../misc/TimeDelta";

export enum MiscLayer {
    SightingsWithImages = "sightingsWithImages",
    PrioritySightings = "prioritySightings",
    PrioritySightingsWithImages = "prioritySightingsWithImages",
    BirdSightings = "birdSightings",
    BirdSightingsWithImages = "birdSightingsWithImages",
    PriorityBirdSightings = "priorityBirdSightings",
    PriorityBirdSightingsWithImages = "priorityBirdSightingsWithImages",
    EllipseOutlines = "ellipseOutlines",
    BearingLineOutlines = "bearingLineOutlines",
    // we end up needing 2 different layers for tracklines, since they get put 
    // in different sources so they can be styled very differently
    GliderTracklines = "gliderTracklines",
    StationTracklines = "stationTracklines",
}

const MISC_LAYER_PARSER = z.nativeEnum(MiscLayer);

export function isMiscLayer(x: unknown): x is MiscLayer {
  return MISC_LAYER_PARSER.safeParse(x).success;
} 



/** 
 * In some cases, a data source itself is it's only layer,
 * while in other cases, a single source might be split 
 * up between several layers to render features differently 
 * (namely used in the case of sightings) 
 */
export type Layer = FeatureType | MiscLayer;

export function isLayer(x: unknown): x is Layer {
  return isMiscLayer(x) || isFeatureType(x);
}




/* eslint-disable-next-line @typescript-eslint/no-namespace */
export namespace LayerType {
    export type Circle = CircleLayer;
    export type Fill = FillLayer;
    export type Line = LineLayer;
    export type Symbol = SymbolLayer;

    export type Any = CircleLayer | FillLayer | LineLayer | SymbolLayer;
}






export interface IconNameParts {
  readonly client: string;
  readonly layer: Layer;
  readonly ageDecayIndex?: number;
  readonly fillColor?: string;
}

export class IconName implements IconNameParts {
  readonly client: string;
  readonly layer: Layer;
  readonly ageDecayIndex?: number;
  readonly fillColor?: string;


  private formatted: null | string = null;

  constructor(client: string, layer: Layer, ageDecayIndex?: number, fillColor?: string) {
    this.client = client;
    this.layer = layer;
    this.ageDecayIndex = ageDecayIndex;
    this.fillColor = fillColor;
  }

  formatIconName = (): string => {
    if (typeof this.formatted !== "string") {
      const base = `${this.client}-${this.layer}`;
    
      if (typeof this.ageDecayIndex === "number") {
        this.formatted = base + "-" + String(this.ageDecayIndex).padStart(2, "0");
      } else if (typeof this.fillColor === "string") {
        this.formatted = base + "-" + this.fillColor;
      } else {
        this.formatted = base;
      }
    }

    return this.formatted;
  };

  static parseIconName = (iconName: string): null | IconName => {
    const parts = iconName.split("-");
    // needs to be either 'client-layer' or 'client-layer-ageDecayIndex'
    if (parts.length !== 2 && parts.length !== 3) {
      return null;
    }

    const client = parts[0];
    const layer = parts[1];
    const ageDecayIndexOrFillColor: string | number | undefined = parts[2];

    let fillColor: undefined | string = undefined;
    let ageDecayIndex: undefined | number = undefined;


    if (!isLayer(layer)) {
      return null;
    }

    if (typeof ageDecayIndexOrFillColor === "string") {
      if (isAFillColor(ageDecayIndexOrFillColor)) {
        fillColor = ageDecayIndexOrFillColor;
      } else {
        const result = z.coerce.number().int().safeParse(ageDecayIndex);
            
        if (!result.success) {
          console.warn(result.error);
          return null;
        }
              
        ageDecayIndex = result.data;
      }    
    }

    return new IconName(client, layer, ageDecayIndex, fillColor);
  };
}

function isAFillColor(s: string): boolean {
  // if its a hex color (i.e '#FF00FF') 
  if (s.startsWith("#") && s.length === 7) {
    return true;
  }

  if (s.startsWith("rgb(") || s.startsWith("rgba(")) {
    return s.endsWith(")");
  }

  return false;
}



export enum Filter {
    Include,
    Exclude,
}

export interface ClientFilter { 
    client: string;
    type: Filter;
}

export interface LayerParamOptions {
    visible: boolean;
    clientFilter?: null | ClientFilter;
    timeDelta?: TimeDelta;
}


export interface AddIconParams {
    map: mapboxgl.Map;
    iconName: IconName;
    // The client color. Its up to the layer to determine the fill color.
    borderColor: ColorString | RGB | HSL;
}




interface BaseLayerConfig<L extends LayerType.Any> {
    factory: (x?: LayerParamOptions) => L;
    sourceId: FeatureType;
    /** __MUST__ be unique across all Layers defined, errors will be thrown to prevent this */
    layerId: Layer;
    addIcon?: never;
    /**
     * Whether or not this layer has an age decay color scheme.  
     */
    hasAgeDecay?: boolean;
    defaultFillColor?: ColorString | RGB | HSL;
}

/** 
 * Sentinel type indicating a layer uses a builtin icon. This way, all Symbol layers 
 * are 'forced' to indicate where the icon comes from, if not from a builder fn 
 * right then and there. 
 */
export const BUILTIN_ICON = Symbol("builtin icon");
export type BuiltInIcon = typeof BUILTIN_ICON;


export enum IconResult {
    Added = "added",
    NotAdded = "notAdded",
}


export type AddIconFn = (params: AddIconParams) => IconResult | Promise<IconResult>;

interface SymbolLayerConfig extends Omit<BaseLayerConfig<LayerType.Symbol>, "addIcon"> {
    /**
     * If applicable, the icon definition.
     */
    icon?: Icon; 
    /**
     * 
     */
    addIcon: BuiltInIcon | AddIconFn;
    /**
     * Some icons are expensive to load, so we should do so lazily if this is flagged as true. 
     * Only relevant if 'addIcon' is an actual function.
     */
    shouldLazilyLoadIcons?: boolean;
}


/**
 * An object that has all the info needed to configure + manage a layer.
 */
export type LayerConfig<L extends LayerType.Any>
    = L extends LayerType.Symbol
        ? SymbolLayerConfig 
        : BaseLayerConfig<L>;


