import type { Map } from "mapbox-gl";
import { HSL, ColorString, RGB } from "./Colors";


// "#00b800";
export const DEFAULT_SIGHTING_FILL_COLOR: HSL = RGB.toHsl({ 
  red: 0,
  green: 0xb8,
  blue: 0,
});

export const SIGHTING_DECAY_COLORS: HSL[] = Array.from(HSL.hslRange(DEFAULT_SIGHTING_FILL_COLOR, 10));


// "#4472c4"
export const DEFAULT_BIRD_SIGHTING_FILL_COLOR: HSL = RGB.toHsl({
  red: 0x44,
  green: 0x72,
  blue: 0xc4,
});

export const BIRD_SIGHTING_DECAY_COLORS: HSL[] = Array.from(HSL.hslRange(DEFAULT_BIRD_SIGHTING_FILL_COLOR, 10));



const DEFAULT_PRIORITY_SIGHTING_FILL_COLOR: ColorString = "#ff0000" as ColorString;

// #ffcd5c;
const DEFAULT_STATION_FILL_COLOR: RGB = { red: 0xff, green: 0xcd, blue: 0x5c };


const ICON_PULSE_DURATION_MS = 5000;



export interface Size {
    height: number;
    width: number;
}

export function formatColor(color: ColorString | RGB | HSL): ColorString {
  if (typeof color === "string") {
    return color;
  } else if ("red" in color) {
    return RGB.format(color);
  } else {
    return HSL.format(color);
  }
}


export function loadSvgImage(iconSvg: string, size: number | Size = 100): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    try {
      // make into a `Size` regardless of the incoming type
      if (typeof size === "number") {
        size = { width: size, height: size };
      }

      // console.log()
      const inlineSvg = "data:image/svg+xml;charset=utf-8;base64," + btoa(iconSvg);

      const image = new Image(size.width, size.height);

      image.onload = () => resolve(image);
      image.onerror = reject;

      image.src = inlineSvg;
    } catch (err) {
      reject(err);
    }
  });
}


export function polygonIconSvg(
  borderColor: ColorString | RGB | HSL,
  fillColor: ColorString | RGB | HSL,
): string {
  const borderColorHex = formatColor(borderColor);
  const fillColorHex = formatColor(fillColor);

  return (
    `<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <rect width="95" height="95" fill="${fillColorHex}" stroke="${borderColorHex}" stroke-width="10"/>
        </svg>`
  );
}

export function birdSightingIconSvg(
  borderColor: ColorString | RGB | HSL,
  fillColor: ColorString | RGB | HSL = DEFAULT_BIRD_SIGHTING_FILL_COLOR,
): string {
  const borderColorHex = formatColor(borderColor);
  const fillColorHex = formatColor(fillColor);

  return (
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100">
            <polygon points="50,0 0,100 100,100" style="fill:${borderColorHex}"/>
            <polygon points="50,15 13,90 87,90" style="fill:${fillColorHex}"/>
        </svg>`
  );
}

export function bearingLineIconSvg(
  lineColor: ColorString | RGB | HSL,
): string {
  return (
    `<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <line x1="10" y1="90" x2="90" y2="10" stroke="${formatColor(lineColor)}" stroke-width="5" stroke-linecap="round"/>
        </svg>`
  );
}

export function ellipseIconSvg(
  borderColor: ColorString | RGB | HSL,
  fillColor: ColorString | RGB | HSL,
): string {
  const borderColorHex = formatColor(borderColor);
  const fillColorHex = formatColor(fillColor);

  return (
    `<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <ellipse 
                cx="50" cy="50" 
                rx="35" ry="45" 
                transform="rotate(45 50 50)" 
                fill="${fillColorHex}" stroke="${borderColorHex}"
                stroke-width="5"
            />
        </svg>`
  );
}


export function regularSightingSvg(
  borderColor: ColorString | RGB | HSL,
  fillColor: ColorString | RGB | HSL = DEFAULT_SIGHTING_FILL_COLOR
): string {
  const borderColorHex = formatColor(borderColor);
  const fillColorHex = formatColor(fillColor);

  // follows the same stroke width/radius ratio as the sightingWithImg svg lower down in the file
  return (
    `<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="50" cy="50"
                r="49"
                fill="${borderColorHex}" 
                stroke-width="2"
                stroke="#000000"
            />    
            <circle 
                cx="50" cy="50" 
                r="33"
                fill="${fillColorHex}"
            />
        </svg>`
  );
}


export function prioritySightingSvg(
  borderColor: ColorString | RGB | HSL, 
  fillColor: ColorString | RGB | HSL = DEFAULT_PRIORITY_SIGHTING_FILL_COLOR
): string {
  const borderColorHex = formatColor(borderColor);
  const fillColorHex = formatColor(fillColor);

  return ( 
    `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="80 216 800 760">
            <path 
                d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-355Z"
                fill="${borderColorHex}"
                stroke="#000000"
                stroke-width="2"
            />
            <path 
                d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Z"
                fill="${fillColorHex}"
            />
        </svg>`
  );

  /// <svg xmlns="http://www.w3.org/2000/svg" viewBox="80 216 800 760">             <path d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-355Z" fill="${borderColorHex}"></path>             <path d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Z" fill="${fillColorHex}">
  /// </path>
  /// </svg>
}


export function priorityBirdSightingIconSvg(
  borderColor: ColorString | RGB | HSL,
  fillColor: ColorString | RGB | HSL = DEFAULT_BIRD_SIGHTING_FILL_COLOR,
): string {
  return prioritySightingSvg(borderColor, fillColor);
}


interface StyleImageInterface {
    height: number;
    width: number;
    map: Map;
    context: null | CanvasRenderingContext2D;
    data: Uint8ClampedArray;
    onAdd: () => void;
    render: () => boolean;
}

export function newSightingWithImage(
  map: Map,
  borderColor: ColorString | RGB | HSL,
  fillColor: ColorString | RGB | HSL = DEFAULT_SIGHTING_FILL_COLOR,
  size = 64,
): StyleImageInterface {
  const borderColorHex = formatColor(borderColor);
  const fillColorHex = formatColor(fillColor);
    
  return {
    map,
    width: size,
    height: size,
    context: null,
    data: new Uint8ClampedArray(size * size * 4),
    onAdd: function() {
      const canvas = document.createElement("canvas");
      canvas.height = size;
      canvas.width = size;
      this.context = canvas.getContext("2d", { willReadFrequently: true })!;
    },
    render: function() {
      if (!this.context) {
        return false;
      }

      const phase = 
                (performance.now() % ICON_PULSE_DURATION_MS) 
                * (2 * Math.PI / ICON_PULSE_DURATION_MS);
            
      // normalizes from -1..1 to 0..0.25 
      const t = (Math.sin(phase) + 1) / 8;

      // 0.5 is the maximum allowed radius (within a unit square),
      // and 't' can be half of that at most.
      const normalizedRadius = 0.5 - t;

      // since half of the stroke falls outside the radius, the outer edge of 
      // the circle will clip with the edges of the canvas if not accounted for.
      const fullRadius = size * normalizedRadius;
      const strokeWidth = fullRadius / 3;
      // subtracting half the stroke will keep everything within the 
      // canvas bounds.
      const radius = fullRadius - (strokeWidth / 2);

      // clear the canvas
      this.context.clearRect(0, 0, size, size);
            
      // draw the circle
      this.context.beginPath();
      this.context.arc(size / 2, size / 2, radius, 0, Math.PI * 2);
      this.context.fillStyle = fillColorHex;
      this.context.strokeStyle = borderColorHex;
      this.context.lineWidth = strokeWidth;
      this.context.fill();
      this.context.stroke();
            
      this.data = this.context.getImageData(0, 0, size, size).data;
      map.triggerRepaint();
      return true;
    },
  };
}



export function sightingWithImageSvg(
  borderColor: ColorString | RGB | HSL,
  fillColor: ColorString | RGB | HSL = DEFAULT_SIGHTING_FILL_COLOR,
): string {
  const borderColorHex = formatColor(borderColor);
  const fillColorHex = formatColor(fillColor);

  return (`\
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" fill="${borderColorHex}">
                <animate
                    attributeName="r"
                    values="17;25;17"
                    dur="5s"
                    repeatCount="indefinite"
                    begin="0" 
                />
            </circle>
            <circle cx="50" cy="50" fill="${fillColorHex}">
                <animate
                    attributeName="r"
                    values="13.6;20;13.6"
                    dur="5s"
                    repeatCount="indefinite"
                    begin="0" 
                />
            </circle>
        </svg>`
  );
}    
    
export function stationIconSvg(
  borderColor: ColorString | RGB | HSL,
  fillColor: ColorString | RGB | HSL = DEFAULT_STATION_FILL_COLOR
): string {
  const borderColorHex = formatColor(borderColor);
  const fillColorHex = formatColor(fillColor);

  return (
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100">
            <path d="M0 100 L50  0 L100 100 L50 75 Z" stroke="black" fill="${borderColorHex}" />
            <path d="M22 78 L50 22 L 78  78 L50 64 Z" stroke="black" fill="${fillColorHex}" />
        </svg>`
  );
}



export function buoyIconSvg(
  fillColor: ColorString | RGB | HSL, 
  secondaryFillColor?: ColorString | RGB | HSL,
): string {
  const fill = formatColor(fillColor);
  const secondary = secondaryFillColor ? formatColor(secondaryFillColor) : fill;

  return `<svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M20.935 12.335h-1.798c-.834 0-1.527-.035-2.11.666-.456.55-.354 1.264-.354 1.931 0 1.128-.204 2.664 1.198 3.068.301.087.623.063.933.063 0 .48.048.989-.02 1.465-.128.892-.436 1.79-.655 2.664l-1.631 6.527c-.098.392-.143.898-.335 1.255-.156.29-.612.215-.889.313a2.879 2.879 0 00-1.187.83c-.637.721-.611 1.49-.611 2.397v2.197c-.59-.5-1.605-.671-2.331-.33-.562.265-1.029.708-1.599.979-.429.204-.927.337-1.398.398-.559.072-1.003.055-1.465.44-.287.24-.533.541-.62.911-.106.443-.046.946-.046 1.399v1.798c0 .72-.022 1.321.535 1.859.639.616 1.511.472 2.328.472h32.035c.957 0 1.847.118 2.496-.733.37-.487.302-1.088.302-1.665v-1.864c0-.89.043-1.63-.733-2.23-.445-.343-.875-.32-1.399-.387a4.794 4.794 0 01-1.332-.367c-.6-.273-1.08-.734-1.665-1.01-.726-.341-1.74-.17-2.33.33v-2.33c0-.822.031-1.54-.546-2.198-.32-.365-.72-.721-1.186-.882-.287-.1-.796-.03-.956-.327-.192-.357-.236-.863-.334-1.255L31.6 22.192c-.219-.875-.527-1.772-.655-2.664-.068-.476-.02-.985-.02-1.465.31 0 .632.024.933-.063 1.403-.404 1.199-1.94 1.199-3.068 0-.667.1-1.381-.355-1.93-.583-.702-1.276-.667-2.11-.667h-1.798c0-1.055.194-2.302-.326-3.263-.518-.958-1.455-1.79-2.538-2.02-.367-.079-.758-.045-1.132-.045-.506 0-.986.007-1.465.195-.72.283-1.361.847-1.812 1.47-.781 1.077-.586 2.406-.586 3.663z" />
    <path d="M22.734 14.133h2.131V8.805c-.371.07-.734.111-1.065.309-1.055.629-1.066 1.73-1.066 2.821z" fill="#f37c00" />
    <path d="M24.865 8.806v5.328h2.131v-2.398c0-.983-.01-1.96-.932-2.56-.376-.246-.77-.29-1.199-.37z" fill="#ed6c00" />
    <path d="M18.471 14.133v2.131h6.394v-2.131z" fill="${fill}" />
    <path
        d="M24.865 14.133v2.131h-4.262v1.066h4.262v3.996h2.398c-.016.619-.73 1.175-1.133 1.598-.382.402-1.033.938-1.225 1.465-.13.356-.088 1.026-.03 1.399.043.28.26.463.437.666.334.381.671.761 1.017 1.132.751.807 1.472 1.645 2.21 2.464.566.63 1.087 1.137 1.388 1.932h-5.062v6.66c.963-.076 1.923-.227 2.797-.664.324-.162.637-.354.933-.563.15-.108.334-.296.532-.296.199 0 .382.188.533.296.296.21.607.395.933.554.856.416 1.697.458 2.597.673.316-.448.804-.762 1.044-1.266.444-.934.221-2.254.221-3.263 0-.61.145-1.46-.406-1.888-.517-.4-1.38-.243-1.991-.243-.09-1.107-.526-2.258-.807-3.33a254.75 254.75 0 01-1.45-5.861c-.205-.864-.51-1.72-.652-2.597-.073-.451-.022-.943-.022-1.399v-2.53h2.132v-2.132z"
        fill="${secondary}" />
    <path
        d="M20.602 17.33c0 .91.109 1.897-.02 2.797-.147 1.03-.502 2.056-.754 3.064-.521 2.084-1.053 4.17-1.55 6.26-.198.837-.492 1.679-.606 2.531-.629 0-1.545-.17-2.05.293-.38.347-.348.837-.348 1.305v2.132c0 .517-.027 1.04.164 1.531.217.558.873.86 1.035 1.399.93-.173 1.794-.25 2.664-.673.326-.159.637-.344.932-.554.151-.108.334-.296.533-.296s.382.188.533.296c.316.224.649.419.999.585.898.428 1.778.465 2.73.642v-6.66H19.87c.105-.776.753-1.317 1.264-1.865.913-.98 1.8-1.991 2.685-2.997.312-.355.907-.794 1.025-1.265.092-.368.117-1.188-.046-1.532-.306-.644-1.028-1.197-1.493-1.732-.324-.373-.84-.734-.837-1.265h2.397V17.33z"
        fill="${fill}" />
    <path
        d="M22.585 21.395c-.233.203.165.587.294.73.498.56 1.006 1.11 1.509 1.666.173.19.378.518.658.308.654-.493 1.188-1.305 1.745-1.907.146-.158.49-.462.413-.711-.084-.27-.793-.155-1.007-.155h-2.664c-.236 0-.758-.097-.948.069zM22.134 22.991c-.225.595-.337 1.248-.491 1.865-.344 1.375-.656 2.764-1.04 4.129.612-.451 1.114-1.16 1.617-1.732.627-.712 1.338-1.375 1.912-2.13-.68-.68-1.251-1.53-1.998-2.132zM27.529 22.991c-.41.54-.903 1.023-1.351 1.532-.143.162-.423.366-.44.6-.023.345.58.83.798 1.065.531.573 1.057 1.152 1.581 1.731.324.36.626.772 1.01 1.066-.39-1.387-.707-2.798-1.057-4.196-.134-.538-.187-1.367-.54-1.798zM24.798 25.988c-.4.575-.92 1.083-1.393 1.598-.706.768-1.4 1.549-2.09 2.331-.567.645-1.265 1.193-1.445 2.065h9.99c-.175-.843-.831-1.38-1.388-1.998l-2.397-2.664c-.405-.45-.781-.982-1.277-1.332z" />
    <path
        d="M7.815 38.642v1.066c.983-.001 1.954-.222 2.864-.586.296-.12.588-.247.866-.405.16-.09.338-.247.533-.238.212.009.423.192.599.295.299.175.61.316.932.44.95.36 1.983.505 2.997.492.932-.011 1.875-.289 2.731-.638.35-.143.895-.605 1.265-.59.213.01.424.193.6.296.298.175.61.316.932.44.94.357 1.93.48 2.93.492.973.013 1.976-.295 2.864-.665.349-.145.766-.511 1.133-.56.335-.043.772.368 1.065.508.662.319 1.46.608 2.198.65 1.176.065 2.26.05 3.397-.314a7.878 7.878 0 001.265-.535c.196-.104.433-.321.666-.311.212.009.423.192.6.295.34.2.696.353 1.065.489.448.165.925.298 1.399.364.4.056.797.006 1.198.08v-1.065a6.245 6.245 0 01-2.863-.683 6.32 6.32 0 01-.866-.54c-.15-.111-.334-.3-.533-.3-.37 0-.81.53-1.132.7-.87.459-1.808.777-2.797.82a6.355 6.355 0 01-3.064-.614c-.35-.169-.682-.36-.999-.584-.15-.106-.336-.318-.533-.318-.196 0-.383.212-.533.318-.317.224-.65.415-.999.584-1.046.507-2.304.754-3.463.588a6.989 6.989 0 01-2.397-.791c-.3-.165-.795-.699-1.133-.699-.337 0-.832.534-1.132.699-.762.419-1.6.695-2.464.8-1.142.141-2.367-.095-3.397-.597a6.426 6.426 0 01-.999-.607c-.15-.11-.333-.3-.532-.3-.176 0-.334.152-.467.25-.258.192-.51.373-.799.516-.953.472-1.927.757-2.997.758z"
        fill="#4ec1f5" />
    <path
        d="M7.815 39.708v2.131h34.1v-2.131a7.546 7.546 0 01-2.931-.6c-.393-.163-.915-.654-1.332-.67-.376-.015-.912.456-1.266.603-.913.38-1.867.655-2.863.667a7.613 7.613 0 01-2.997-.573 6.17 6.17 0 01-.866-.44c-.158-.095-.34-.25-.533-.257-.376-.015-.912.456-1.265.603-.877.365-1.778.623-2.731.664-1.082.048-2.13-.165-3.13-.57a6.17 6.17 0 01-.866-.44c-.158-.095-.34-.25-.533-.257-.362-.014-.862.432-1.199.576a8.741 8.741 0 01-2.664.683c-1.109.105-2.241-.148-3.263-.562a6.17 6.17 0 01-.866-.44c-.158-.095-.34-.25-.533-.257-.196-.008-.372.147-.532.24-.256.152-.525.278-.8.39-.941.385-1.907.637-2.93.64z"
        fill="#0299e3" />
  </svg>`;
}